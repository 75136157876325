<template>
  <div class="overview-main">
    <form action="" class="settings-form" method="POST">
      <div class="container-fluid">
        <div class="row">
          <div class="col p-0">
            <div class="new-section upcoming">
              <div class="section-text">
                <h6>Upcoming Appointment: </h6>
                <div class="next-appt-date">Fri, <span class="date">24th</span> September, 2021</div>
              </div>
              <img src="../../assets/undraw_medical_research.svg" class="upcoming-appt-icon" alt="upcoming-appt-icon">
            </div>
            <div class="new-section mt-3">
              <h5 class="section-title mb-3">Additional Information</h5>
              <div class="additional-details-grid">
                <div class="additional-details">
                  <div class="wrapper">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'id-icon'" v-bind:id="'id-card'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.652 8.21a1.653 1.653 0 11-3.305 0 1.653 1.653 0 013.305 0zM10 12.386a2.907 2.907 0 00-2.1.876c-.49.5-.786 1.167-.857 1.895h5.914c-.071-.733-.366-1.395-.857-1.895a2.907 2.907 0 00-2.1-.876z" fill="#000"/><path d="M15.714 0H4.285c-.867 0-1.57.704-1.571 1.571V18.43c0 .867.704 1.57 1.571 1.571h11.429c.867 0 1.57-.704 1.571-1.571V1.57c0-.867-.704-1.57-1.571-1.571zm-7.7 1.576h3.81a.333.333 0 010 .667h-3.81a.333.333 0 010-.667zM10 5.89a2.317 2.317 0 11-.005 4.634A2.317 2.317 0 0110 5.89zm3.304 9.934h-6.61a.331.331 0 01-.332-.333c0-1.039.376-1.996 1.061-2.696a3.622 3.622 0 015.153 0c.685.7 1.062 1.657 1.062 2.696a.33.33 0 01-.334.333z" fill="#000"/></svg>
                  </div>
                  <div class="detail-title">ID</div>
                  <div class="patient-details">{{patient.id}}</div>
                </div>
                <div class="additional-details">
                  <div class="wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:class="'toolbox-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 3a1 1 0 00-1 1H4a2 2 0 00-2 2v2h20V6a2 2 0 00-2-2h-5a1 1 0 00-1-1h-4zm-8 7v8a2 2 0 002 2h16a2 2 0 002-2v-8h-3v.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V10H9v.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V10H2z"/></svg>
                  </div>
                  <div class="detail-title">Occupation</div>
                  <div class="patient-details">{{patient.occupation}}</div>
                </div>
                <div class="additional-details">
                  <div class="wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'blood-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M38.6 20.7s-18 12.6-18 30.1c0 9.9 8.1 18 18 18s18-8.1 18-18c0-17.8-18-30.1-18-30.1zm-8.1 34.2c-1.6-2-2.5-4.4-2.5-6.9 0-2.4.6-4.8 1.8-7.2l1.4.7c-1.1 2.2-1.6 4.4-1.6 6.5 0 2.1.8 4.2 2.1 5.8l-1.2 1.1zM67.6 47.9s-11.7 8.3-11.7 19.6c0 6.5 5.3 11.7 11.7 11.7 6.5 0 11.7-5.3 11.7-11.7.1-11.5-11.7-19.6-11.7-19.6zm-5.5 22.5c-1.1-1.3-1.7-3-1.7-4.7 0-1.6.4-3.2 1.2-4.9l1.4.7c-.7 1.4-1 2.8-1 4.2 0 1.3.5 2.6 1.3 3.6l-1.2 1.1z"/></svg>
                  </div>
                  <div class="detail-title">Blood Type</div>
                  <div class="patient-details">{{patient.blood_type}}</div>
                </div>
                <div class="additional-details">
                  <div class="wrapper">
                    <svg height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'father-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M30.933 32.528c-.146-1.612-.09-2.737-.09-4.21.73-.383 2.038-2.825 2.259-4.888.574-.047 1.479-.607 1.744-2.818.143-1.187-.425-1.855-.771-2.065.934-2.809 2.874-11.499-3.588-12.397-.665-1.168-2.368-1.759-4.581-1.759-8.854.163-9.922 6.686-7.981 14.156-.345.21-.913.878-.771 2.065.266 2.211 1.17 2.771 1.744 2.818.22 2.062 1.58 4.505 2.312 4.888 0 1.473.055 2.598-.091 4.21C19.367 37.238 7.546 35.916 7 45h38c-.545-9.084-12.315-7.762-14.067-12.472z"/></svg>
                  </div>
                  <div class="detail-title">Father's Name</div>
                  <div class="patient-details">{{patient.fathers_name}}</div>
                </div>
                <div class="additional-details">
                  <div class="wrapper">
                    <svg height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mother-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M30.933 32.528a24.382 24.382 0 01-.06-1.226c4.345-.445 7.393-1.487 7.393-2.701-.012-.002-.011-.05-.011-.07-3.248-2.927 2.816-23.728-8.473-23.306-.709-.6-1.95-1.133-3.73-1.133-15.291 1.157-8.53 20.8-12.014 24.508l-.007.001.001.006-.001.002.002.001c.014 1.189 2.959 2.212 7.178 2.668-.012.29-.037.649-.092 1.25C19.367 37.238 7.546 35.916 7 45h38c-.545-9.084-12.315-7.762-14.067-12.472z"/></svg>
                  </div>
                  <div class="detail-title">Mother's Name</div>
                  <div class="patient-details">{{patient.mothers_name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  <router-view name="default" />
  </div>
</template>

<script>
// import { VueTelInput } from 'vue-tel-input'
import { mapState } from 'vuex'
export default {
  name: 'PatientOverview',
  components: {
    // VueTelInput
  },
  data () {
    return {
      edit: false
    }
  },
  computed: {
    ...mapState(['patient'])
  },
  methods: {
    toggleEdit () {
      this.edit = !this.edit
    }
  }
}
</script>

<style lang="scss" scoped>
.overview-main {
  background-color: var(--background-primary);
  padding: 1rem;
  margin-top: 30px;
  border-radius: 0.75rem;
  border: 1px transparent;
}

::v-deep svg {
  width: 20px;
  height: 20px;
}

.new-section {
  margin-bottom: 25px;
}

.settings-form {
  position: relative;
}
.save-button, .cancel-button {
  padding: 0.325rem 0.625rem;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  width: 100px;
  font-size: 0.75rem;
  border-radius: 5px;
  background: var(--button-blue);
  color: white;
  // position: sticky;
  // top: 20px;
  // right: 0;
}

.cancel-button {
  margin-left: auto;
  margin-right: 0.625rem;
  background-color: var(--light-gray);
  color: var(--primary);
  border: 1px solid #ccc;
}

::v-deep .upcoming {
  // border: 1px solid;
  min-height: 150px;
  background-color: #fafafa;
  box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 15px;
  overflow: hidden;
  width: 55%;
  position: relative;
  display: flex;
  h6 {
    color: black;
  }
  .next-appt-date {
    font-size: 2rem;
    // margin-top: 10px;
    .date {
      font-size: 2rem;
      color: var(--primary-accent-dark);
    }
  }
  .upcoming-appt-icon {
    width: 200px;
    object-fit: contain;
    margin-left: auto;
    // display: flex;
    // position: absolute;
    // bottom: 4px;
    // right: 15px;
  }
}

::v-deep .additional-details-grid {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-gap: 18px;
  .additional-details {
    box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
    background-color: white;
    // padding: 10px;
    border-radius: 5px;
    // aspect-ratio: 1 / 1;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .wrapper {
      // border: 1px solid;
      position: absolute;
      width: 100%;
      height: 80%;
      top: 20%;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      display: flex;
    }
    svg {
      // position: absolute;
      // right: 5px;
      // bottom: 5px;
      margin: auto auto;
      display: flex;
      width: 100%;
      height: 50%;
      z-index: 0;
      * {
        fill: var(--primary-accent-dark);
        opacity: 0.15;
      }
    }
    #id-card {
      height: 30%;
    }
    .detail-title {
      font-size: 0.875rem;
      background-color: var(--primary-accent-dark);
      color: white;
      padding: 3px 6px;
      text-align: center;
      height: 20%;
    }
    .patient-details {
      display: flex;
      margin: auto auto;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
      z-index: 3;
      // border: 1px solid;
      width: fit-content;
      align-self: flex-end;
      text-align: center;
    }
  }
}
</style>
